"use strict";

document.addEventListener("DOMContentLoaded", function () {
  // Main Template Color
  var brandPrimary = "#33b35a";

  // ------------------------------------------------------- //
  // Side Navbar Functionality
  // ------------------------------------------------------ //
  const sidebarToggleBtn = document.getElementById("toggle-btn");
  const sidebar = document.querySelector(".side-navbar");
  ``;
  const pageHolder = document.querySelector(".page");
  const sidebarHeading = document.querySelectorAll(".side-navbar .heading");
  const navbar = document.querySelector(".navbar.fixed-top");

  if (sidebarToggleBtn) {
    sidebarToggleBtn.addEventListener("click", (e) => {
      e.preventDefault();
      if (window.outerWidth > 1194) {
        sidebar.classList.toggle("shrink");
        pageHolder.classList.toggle("active");
        navbar.classList.toggle("active");
        sidebarHeading.forEach((el) => {
          el.classList.toggle("mx-lg-2");
        });
      } else {
        sidebar.classList.toggle("show-sm");
        navbar.classList.toggle("active-sm");
      }
    });
  }

  window.addEventListener("resize", function () {
    if (this.outerWidth > 1194) {
      sidebar.classList.remove("show-sm");
      pageHolder.classList.remove("active");
      navbar.classList.remove("active-sm");
    } else {
      sidebar.classList.remove("shrink");
      pageHolder.classList.remove("active");
      navbar.classList.remove("active");
    }
  });

  // ------------------------------------------------------- //
  // Tooltips init
  // ------------------------------------------------------ //
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // ------------------------------------------------------- //
  // Material Inputs
  // ------------------------------------------------------ //

  let materialInputs = document.querySelectorAll("input.input-material");
  let materialLabels = document.querySelectorAll("label.label-material");

  // activate labels for prefilled values
  let filledMaterialInputs = Array.from(materialInputs).filter(function (input) {
    return input.value !== "";
  });
  filledMaterialInputs.forEach((input) =>
    input.parentElement.lastElementChild.setAttribute("class", "label-material active")
  );

  // move label on focus
  materialInputs.forEach((input) => {
    input.addEventListener("focus", function () {
      input.parentElement.lastElementChild.setAttribute("class", "label-material active");
    });
  });

  // remove/keep label on blur
  materialInputs.forEach((input) => {
    input.addEventListener("blur", function () {
      if (input.value !== "") {
        input.parentElement.lastElementChild.setAttribute("class", "label-material active");
      } else {
        input.parentElement.lastElementChild.setAttribute("class", "label-material");
      }
    });
  });
  materialLabels.forEach((input) => {
    input.addEventListener("click", function () {
      input.setAttribute("class", "label-material active");
      //             if (input.value !== "") {
      //                 input.parentElement.lastElementChild.setAttribute("class", "label-material active");
      //             } else {
      //             }
    });
  });

  function bsValidationBehavior(errorInputs, form) {
    function watchError() {
      errorInputs.forEach((input) => {
        if (input.classList.contains("js-validate-error-field")) {
          input.classList.add("is-invalid");
          input.classList.remove("is-valid");
        } else {
          input.classList.remove("is-invalid");
          input.classList.add("is-valid");
        }
      });
    }
    watchError();
  }

  // ------------------------------------------------------- //
  // External links to new window
  // ------------------------------------------------------ //
  document.querySelectorAll(".external").forEach((el) => {
    el.addEventListener("click", function (e) {
      e.preventDefault();
      window.open(el.getAttribute("href"));
    });
  });

  // ------------------------------------------------------- //
  // Masonry with ImagesLoaded
  // ------------------------------------------------------ //
  const masonryGrid = document.querySelector(".msnry-grid");
  if (masonryGrid) {
    var msnry = new Masonry(masonryGrid, {
      percentPosition: true,
    });
    imagesLoaded(masonryGrid).on("progress", function () {
      msnry.layout();
    });
  }

  OverlayScrollbars(document.querySelector(".side-navbar"), {
    overflowBehavior: {
      x: "hidden",
    },
  });
});

// ------------------------------------------------------- //
//   Inject SVG Sprite -
//   see more here
//   https://css-tricks.com/ajaxing-svg-sprite/
// ------------------------------------------------------ //
function injectSvgSprite(path) {
  var ajax = new XMLHttpRequest();
  ajax.open("GET", path, true);
  ajax.send();
  ajax.onload = function (e) {
    var div = document.createElement("div");
    div.className = "d-none";
    div.innerHTML = ajax.responseText;
    document.body.insertBefore(div, document.body.childNodes[0]);
  };
}
// this is set to BootstrapTemple website as you cannot
// inject local SVG sprite (using only 'icons/orion-svg-sprite.svg' path)
// while using file:// protocol
// pls don't forget to change to your domain :)
injectSvgSprite("https://api.bukazu.com/icons/orion-svg-sprite.svg");
